

/*
const columns = computed(()=>{
    let c0 = [];
    let c1 = [];
    let c2 = [];
    if (medias.value == undefined){
        return [c0, c1, c2]
    }
    medias.value.forEach((el, index)=>{
        if ((index % 3) == 0){
            c0.push(el)
        } else if ((index % 3) == 1){
            c1.push(el)
        } else {
            c2.push(el)
        }
    })
    return [c0, c1, c2]
})
*/

// by convention, composable function names start with "use"
export function makeColumns(list) {
    let c0 = [];
    let c1 = [];
    let c2 = [];
    if (list == undefined){
        return [c0, c1, c2]
    }
    list.forEach((el, index)=>{
        if ((index % 3) == 0){
            c0.push(el)
        } else if ((index % 3) == 1){
            c1.push(el)
        } else {
            c2.push(el)
        }
    })
    return [c0, c1, c2]
}