<template>
    <Switch leftChoice_display="Image" leftChoice="image" rightChoice_display="video" rightChoice="video" v-model="videoMode" class="center searchMode"  height="30px" borderWidth="2px" borderRadius="8px" borderColor="var(--color2)"/>
    <SearchBar style="margin: 20px 5% 0 5%; width: 90%;" @search="searchMedia"/>
    <div class="card_gallery" library="giphy" ref="galery" style="height:calc(100% - 88px)">
        <div class="gallery_column" v-for="(medias) in columns" :key="medias">
                <div class="choice_card" v-for="media in medias" :key="media._id" :id="`media_${media._id}_image`" @click.stop="selectMedia(media)" @mouseover="autoPlayVideo(media._id)" @mouseleave="stopAutoPlayVideo(media._id)">
                    <video v-if="/video/.test(media.media_mimetype)" :src="media.preview_url" style="position: absolute;" preload="none" muted loop :id="`media_${media._id}_video`" v-show="mediaflyoverId == media._id"></video>
                    <img v-if="media.media_thumbnail" :src="media.media_thumbnail" />
                    <div class="video_duration" v-if="/video/.test(media.media_mimetype)">
                        <p>{{ renderMsShort(media.media_duration) }}</p>
                    </div>
            </div>
        </div>
    </div>
    <div class="loadingMore" v-if="fetchingMedia == true"><span class="loading_spinner" style="--color : var(--color2)" ></span></div>

</template>


<script setup>
import { defineAsyncComponent, defineEmits, ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { makeColumns } from "@/components/Composables/columns.js";
import { renderMsShort } from "../../../../lib/date-time-duration";
const SearchBar = defineAsyncComponent(() => import("../../../Object/searchBar/SearchBar.vue"));

const emit = defineEmits(["mediaSelected"]);

const medias = ref([]);
const galery = ref();
const fetchingMedia = ref(false);

const columns = computed(()=>{
    return makeColumns(medias.value)
})

const fetchParams = ref({
    offset : 0,
    mode : "image",
    searchQuery : "Pexels"
})

const mediaflyoverId = ref(-1);

function autoPlayVideo(id){
    //Find media
    const media = medias.value.find(m => m._id == id)

    if (media == undefined || !/video/.test(media.media_mimetype)){
        return;
    }

    mediaflyoverId.value = id;
    
    document.getElementById(`media_${id}_video`).play();
}

function stopAutoPlayVideo(id){
    //Find media
    const media = medias.value.find(m => m._id == id)

    if (media == undefined || !/video/.test(media.media_mimetype)){
        return;
    }

    mediaflyoverId.value = null;

    document.getElementById(`media_${id}_video`).pause();
    document.getElementById(`media_${id}_video`).currentTime = 0;
}

const videoMode = computed({
  get() {
    return fetchParams.value.mode == "video"
  },
  set(newValue) {
    fetchParams.value.mode = (newValue == true ? "video" : "image");
    fetchParams.value.offset = 0;
    medias.value = [];
    fetchMedias();
  }
})

onMounted(()=>{
    fetchMedias();
    galery.value.addEventListener("scroll", onScroll);
});

var lastScrollPos = 0;

function onScroll(){
    
    const scrollDown = (lastScrollPos < galery.value.scrollTop);
    lastScrollPos = galery.value.scrollTop;

    if (scrollDown){
        if (galery.value.offsetHeight >= (galery.value.scrollHeight - galery.value.scrollTop - 20)) {
            if (fetchingMedia.value == false){
                fetchMedias();
            }
        }
    }
};

function fetchMedias(){
    fetchingMedia.value = true;

    axios.post("/overlays/pexels", {
        type : fetchParams.value.mode,
        searchQuery : fetchParams.value.searchQuery,
        offset : fetchParams.value.offset
    }).then(res =>{
        fetchingMedia.value = false
        fetchParams.value.offset += 1;
        medias.value.push(...res.data.map(media =>{
                media.media_thumbnail = media.thumbnail;
                media._id = media.id;
                media.media_duration = media.duration
                media.media_mimetype = media.mimetype;
                media.type = (/video/.test(media.mimetype) ? "video" : "image");
                return media;
            })
        );
    })
};


function searchMedia(searchQuery){
    fetchParams.value.offset = 0;
    fetchParams.value.searchQuery = searchQuery;
    medias.value = [];
    fetchMedias();
}


function selectMedia(media){
    const set = {
        type : media.type,
        origin : "pexels",
        static_url : media.url,
        media_height : media.height,
        media_width: media.width,
        media_duration : (media.type == "video" ? media.media_duration : Infinity),
        media_id: null
    }

    emit('mediaSelected', set)
}

</script>


<style lang="scss">
.searchMode{
    width: 90%;
    display: block;
    margin-top: 18px;
}
</style>